import { forwardRef, PropsWithChildren, Ref, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import ChartDataLabels, { Context } from 'chartjs-plugin-datalabels';
import { colourRgb } from '../../../utils/ColourUtils';
import Tooltip from '../Tooltip';
import { nextTick } from '../../../utils/ReactUtils';
import { Chart, ArcElement, Tooltip as TooltipPlugin } from 'chart.js';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import StringUtils from '../../../utils/StringUtils';

Chart.register(ArcElement);
Chart.register(TooltipPlugin);

export enum ChartSize {
  SMALL,
  LARGE,
}

type DoughnutChartProps = {
  id?: string;
  dataset: number[];
  dataLabels: string[];
  disabled?: boolean;
  ref?: Ref<Chart<'doughnut'>>;
  title?: string;
  hideLabels?: boolean;
  size?: ChartSize;
  colours?: string[];
};

const DoughnutChart = forwardRef<ChartJSOrUndefined<'doughnut', number[], unknown> | null, PropsWithChildren<DoughnutChartProps>>((props, ref) => {
  const { dataLabels, dataset, disabled, title, id, hideLabels = false, size = ChartSize.LARGE, colours, children } = props;
  const contentRef = useRef<HTMLDivElement>(null);
  const canvas = contentRef.current?.querySelector('canvas');
  const [canvasSize, setCanvasSize] = useState(Math.min(canvas?.getBoundingClientRect().height ?? 0, canvas?.getBoundingClientRect().width ?? 0));

  useEffect(() => {
    // NOTE: do NOT use `canvas` from the outside scope in this useEffect, as it will be the old canvas (which may be null)
    const update = () => {
      const canvas = contentRef.current?.querySelector('canvas');
      // wait untill canvas is rendered
      if (!canvas) setTimeout(update, 10);
      else setCanvasSize(Math.min(canvas?.getBoundingClientRect().height ?? 0, canvas?.getBoundingClientRect().width ?? 0));
    };

    update();
  }, [canvas]);

  const [hidden, setHidden] = useState(false);
  const [animate, setAnimate] = useState(true);

  const navigate = useNavigate();
  const grayRgb = (index: number) => getComputedStyle(document.documentElement).getPropertyValue(`--color-gray-${index}`);
  const dataColors = useMemo(() => {
    if (disabled) {
      return [4, 3, 5].map((x) => `rgba(${grayRgb(x)}, 0.5)`);
    }
    return colours
      ? colours
      : [colourRgb('dashboard-1', 0.5), colourRgb('dashboard-1'), colourRgb('dashboard-2'), colourRgb('dashboard-3'), colourRgb('primary-2')];
  }, [colours, disabled]);

  const resize = useCallback(() => {
    setHidden(true);
    nextTick(() => setHidden(false));
  }, []);

  useEffect(() => {
    const handler = () => {
      setAnimate(false);
      resize();
    };

    window.addEventListener('resize', handler);

    return () => {
      window.removeEventListener('resize', handler);
    };
  }, [resize]);

  const cutoutPercentage = useMemo(() => (size === ChartSize.LARGE ? 55 : 60), [size]);
  const padding = useMemo(() => (size === ChartSize.LARGE ? 24 : 0), [size]);

  if (hidden) {
    return null;
  }

  return (
    <div className="relative flex h-full max-h-full w-full flex-col items-center justify-center" ref={contentRef}>
      <Doughnut
        ref={ref}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        plugins={[ChartDataLabels as any]}
        options={{
          animation: {
            duration: animate ? 1000 : 0,
          },
          maintainAspectRatio: false,
          layout: {
            padding,
          },
          cutout: `${cutoutPercentage}%`,
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: dataLabels.length > 0 && !disabled,
              filter: function (item) {
                return !!item.label;
              },
              callbacks: {
                title: function () {
                  return '';
                },
                label: function (item) {
                  return ' ' + item.label;
                },
              },
            },
            datalabels: {
              display: 'auto',
              font: { family: 'ScandiaWeb, sans-serif' },
              clamp: true,
              anchor: 'end',
              align: 'end',
              formatter: function (value: number, context: Context) {
                return context.chart.data.labels && value > 0 && !hideLabels && !disabled
                  ? `${StringUtils.truncate(`${context.chart.data.labels[context.dataIndex]}`, canvasSize < 300 ? 5 : 20)}, ${value}`
                  : '';
              },
              listeners: {
                enter: function (context) {
                  const chart = context.chart;
                  const meta = chart.getDatasetMeta(context.datasetIndex);
                  const point = meta.data[context.dataIndex];
                  if (chart.tooltip) {
                    chart.tooltip.setActiveElements([{ datasetIndex: context.datasetIndex, index: context.dataIndex }], { x: point.x, y: point.y });
                  }
                },
                leave: function (context) {
                  const chart = context.chart;
                  if (chart.tooltip) {
                    chart.tooltip.active = false;
                  }
                },
              },
            },
          },
        }}
        data={{
          datasets: [
            {
              data: !disabled ? dataset : Array.from({ length: dataset.length }, (v, i) => i + 5),
              backgroundColor: dataColors,
            },
          ],
          labels: dataLabels,
        }}
      />
      <div
        className="absolute top-0 flex items-center justify-center truncate px-4 empty:hidden"
        style={{
          maxWidth: (canvasSize - padding * 2) * (cutoutPercentage / 100),
          maxHeight: (canvasSize - padding * 2) * (cutoutPercentage / 100),
          minHeight: (canvasSize - padding * 2) * (cutoutPercentage / 100),
          marginTop: `calc(${padding}px + (${canvasSize}px - ${padding}px - ${canvasSize * (cutoutPercentage / 100)}px) / 2)`,
        }}
      >
        {children}
      </div>
      <Tooltip text={title}>
        {(tooltip) => (
          <div
            {...tooltip}
            onClick={
              !disabled && id
                ? () => {
                    navigate(`modules/${id}`);
                  }
                : undefined
            }
            className={`text-dpm-14 absolute -bottom-6 w-full px-2 text-center font-medium ${!disabled && id && 'cursor-pointer'}`}
          >
            <div className="truncate">{title}</div>
          </div>
        )}
      </Tooltip>
    </div>
  );
});
DoughnutChart.displayName = 'DoughnutChart';

export default DoughnutChart;
